<template>
  <b-row class="hp-authentication-page">
    <left-item />

    <b-col cols="12" lg="6" class="py-sm-64 py-lg-0">
      <b-row
        align-v="center"
        align-h="center"
        class="right mx-4 mx-sm-n32 mt-5"
      >
        <b-col
          cols="12"
          md="9"
          xl="7"
          class="col-xxxl-5 px-8 px-sm-0 pt-24 pb-48"
        >
          <h1 class="mb-0 mb-sm-24">Login</h1>
          <p class="mt-sm-8 mt-sm-0 text-black-60">
            Selamat Datang, silahkan masuk ke dalam akun ADMISSION BLOOD!
          </p>

          <b-form class="mt-16 mt-sm-32 mb-8">
            <b-form-group label="Email" label-for="loginUsername" class="mb-16">
              <b-form-input
                id="loginUsername"
                v-model="fields.login_id"
                name="email"
                :state="validation.login_id"
                placeholder="Alamat Email"
                type="email"
              />
              <b-form-invalid-feedback :state="validation.login_id">
                {{ fieldErrors.login_id }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Kata Sandi "
              for="registerPassword"
              class="mb-16"
            >
              <div class="input-container" style="color: #111">
                <b-form-input
                  id="registerPassword"
                  v-model="fields.password"
                  name="password"
                  :state="validation.password"
                  :type="showPassword ? 'text' : 'password'"
                  placeholder="Kata Sandi"
                />
                <b-form-invalid-feedback :state="validation.password">
                  {{ fieldErrors.password }}
                </b-form-invalid-feedback>
                <span class="eye-icon" @click="togglePasswordVisibility">
                  <b-icon
                    :icon="showPassword ? 'eye-slash' : 'eye'"
                    class="eye"
                  />
                </span>
              </div>
            </b-form-group>

            <b-row align-v="center" align-h="between" class="mb-16">
              <b-col class="hp-flex-none w-auto">
                <b-form-checkbox>Ingat Saya</b-form-checkbox>
              </b-col>

              <b-col class="hp-flex-none w-auto">
                <b-link
                  class="hp-button text-black-80 hp-text-color-dark-40"
                  to="/pages/authentication/recover-password"
                >
                  Lupa Kata Sandi?
                </b-link>
              </b-col>
            </b-row>

            <b-button
              type="submit"
              variant="danger"
              class="w-100"
              :disabled="isLoading"
              @click="onSubmit"
            >
              <b-spinner v-if="isLoading" small label="Small Spinner" />
              <span v-else>Masuk</span>
            </b-button>
          </b-form>
          <footer-item />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BIcon,
  BSpinner,
  BFormInvalidFeedback,
} from "bootstrap-vue";

import LeftItem from "../LeftItem.vue";
import FooterItem from "../FooterItem.vue";
import { login } from "../../../../api/auth/authAPI";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BIcon,
    BSpinner,
    LeftItem,
    FooterItem,
  },
  data() {
    return {
      errors: "",
      fieldErrors: {
        login_id: undefined,
        password: undefined,
      },
      fields: {
        login_id: "",
        password: "",
      },
      showPassword: false,
      showModal: false,
      hideHeader: true,
      hideFooter: true,
      isLoading: false,
    };
  },
  computed: {
    validation() {
      return {
        login_id: this.fieldErrors.login_id ? false : null,
        password: this.fieldErrors.password ? false : null,
      };
    },
  },
  watch: {
    "fields.login_id": function (newValue) {
      if (newValue) {
        this.fieldErrors.login_id = null;
      }
    },
    "fields.password": function (newValue) {
      if (newValue) {
        this.fieldErrors.password = null;
      }
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.isLoading = true;

      login({
        login_id: this.fields.login_id,
        password: this.fields.password,
      })
        .then((response) => {
          const { token } = response.data.data;
          const informationRole = {
            role: response.data.data.user.role,
            username: response.data.data.user.username,
            name: response.data.data.user.name,
            id: response.data.data.user.id,
          };

          localStorage.setItem("token", token);
          localStorage.setItem("userInfo", JSON.stringify(informationRole));

          // stop button loading
          this.isLoading = false;

          // showing bvToast inside dashboard when login success
          setTimeout(() => {
            this.$bvToast.toast("Login Berhasil", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          }, 500);

          const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));

          // go to route analytics when login success
          const roleToRouteMap = {
            ADMIN: "/dashboards/analytics",
            AFTAP: "/dashboards/activasiaftafs",
            HB: "/dashboards/activasiHB",
            DOCTOR: "/dashboards/doctors",
            LOGISTIK: "/dashboards/sisa-stok",
            REGISTER: "/dashboards/users",
            ALLROLE: "/dashboards/users",
            KOMPONEN: "/lab/processing/blood-received",
            KGD: "/lab/KGD/blood-received",
            IMLTD: "/lab/IMLTD/blood-received",
            PROLIS: "/lab/prolis",
            Logistik: "/master-data/barang",
          };

          const routeForRole = roleToRouteMap[userInfo.role];

          if (routeForRole) {
            this.$router.push(routeForRole);
          }
        })
        .catch((error) => {
          if (error.response) {
            const errorData = error.response.data;
            if (errorData.code === 400) {
              const errorMessage = errorData.message.split("\\n");

              errorMessage.forEach((msg) => {
                if (msg.includes("login id")) {
                  this.fieldErrors.login_id = msg;
                } else if (msg.includes("password")) {
                  this.fieldErrors.password = msg;
                }
              });

              this.isLoading = false;
            }

            if (errorData.code === 500) {
              this.errors = errorData.data.replace(/\\n/g, "");
              this.isLoading = false;

              setTimeout(() => {
                this.$bvToast.toast("Login Gagal", {
                  title: this.errors,
                  variant: "danger",
                  solid: true,
                });
              }, 500);
            }
          } else {
            // Handle other types of errors (e.g., network issues)
            console.error(error);
          }
        });

      this.resetErrors();
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    resetErrors() {
      this.fieldErrors.login_id = "";
      this.fieldErrors.password = "";
    },
  },
};
</script>
<style scoped>
/* mobile screen */

@media only screen and (max-width: 600px) {
  /* .right {
    height: 100vh;
  } */
}

.input-container {
  position: relative;
  color: #111 !important;
}

.input-container input[type="password"],
.input-container input[type="text"] {
  width: 100%;
  padding: 12px 36px 12px 12px;
  box-sizing: border-box;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
